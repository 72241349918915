import React, { Component } from 'react'
import classes from './Description.module.css'
import Header from '../../components/Header/Header'
import BtnFullscreen from '../../components/BtnFullscreen/BtnFullscreen'
import MenuToggle from '../../components/Navigation/MenuToggle/MenuToggle'
import Drawer from '../../components/Navigation/Drawer/Drawer'
import ButtonToMap from '../../components/UI/ButtonToMap/ButtonToMap'
import Tag from '../../components/UI/Tag/Tag'
import Link from '../../components/UI/Link/Link'
import DescriptionPhoto from '../../components/DescriptionPhoto/DescriptionPhoto'
import { withRouter } from 'react-router'

class Description extends Component {
  render() {
    console.log(this.props)

    return (
      <>
        {/* {this.props.points.map((index) => (
        <React.Fragment key={index}> */}
        <Header isHidden={true} />
        <BtnFullscreen />
        <MenuToggle
          onToggle={this.props.toggleMenuHandler}
          isOpen={this.props.menu}
        />
        <Drawer
          isOpen={this.props.menu}
          onClose={this.props.menuCloseHandler}
        />
        <div className={classes.dmain}>
          <hr className={classes.divider} />
          <div className={classes.wrapper}>
            <ButtonToMap onClose={this.props.onClose} />
            <div className={classes.content}>
              <div className={classes.info}>
                <div className={classes.infoTop}>
                  <h1 className={classes.title}>
                    {this.props.match.params.link}
                  </h1>
                  {/* <p className={classes.adress}>Адрес: {this.this.props.adress}</p> */}

                  {/* <div className={classes.tags}>
                        {this.this.props.tags.map((tag, index) => (
                          <Tag key={index} name={tag.name} />
                        ))}
                      </div> */}
                </div>

                {/* <div className={classes.infoBottom}>
                      <h3 className={classes.infoBottomTitle}>Полезные ссылки</h3>
                      {this.this.props.links.map((link, index) => (
                        <Link key={index} href={link.href} />
                      ))}
                    </div> */}
              </div>
            </div>
          </div>
          <hr className={classes.divider} />
          <div className="description-wrapper__photo-group">
            {/* {this.this.props.imgs.map((img, index) => (
                  <DescriptionPhoto key={index} img={img.img} />
                ))} */}
          </div>
        </div>
        {/* </React.Fragment>
      ))} */}
      </>
    )
  }
}

export default withRouter(Description)
