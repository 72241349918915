import React from 'react'
import classes from './One.module.css'
import WrapperBar from '../../WrapperBar/WrapperBar'
import NavCircle from './NavCircle/NavCircle'
import SocialLinks from './SocialLinks/SocialLinks'

export default function One() {

    const load = () => {
        document.querySelectorAll("#nav-item__1")[0].style.width="75px";
        document.querySelectorAll("#nav-item__1")[1].style.width="75px";
    }
    
    const wheel = () => {
        document.querySelectorAll("#nav-item__1")[0].style.width="75px";
        document.querySelectorAll("#nav-item__1")[1].style.width="20px";
        document.querySelectorAll("#nav-item__2")[1].style.width="75px";
        
    }
    
    return (
        <div onLoad={load} onWheel={wheel} className={classes.slide}>

            <div className={classes.backgroundText}>
                <p>PETRO WORKERS</p>
            </div>

            <div className={classes.wrapper}>
                <WrapperBar/>
                <NavCircle/>
                <SocialLinks/>
                
            </div>
            
        </div>
    )
}
