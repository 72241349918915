import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import classes from './NavItem.module.css'

export default class NavItem extends Component {
  render() {
    return (
      <>
        <NavLink to={this.props.to} exact className={classes.item}>
          {this.props.name}
        </NavLink>
      </>
    )
  }
}
