import React, { Component } from 'react'
import classes from './WrapperBar.module.css'
import '../Slides/One/One.module.css'

export default class WrapperBar extends Component {
    render() {
  
   
        

        return (
            <div className={classes.nav}>

                <div className={classes.items}>
                    <h1 className={classes.item}>1</h1>
                    <h1 className={classes.item}>2</h1>
                    <h1 className={classes.item}>3</h1>
                    <h1 className={classes.item}>4</h1>
                </div>

                <div className={classes.lines}>
                    <div id="nav-item__1" className={classes.line}></div>
                    <div id="nav-item__2" className={classes.line}></div>
                    <div id="nav-item__3" className={classes.line}></div>
                    <div id="nav-item__4" className={classes.line}></div>
                </div>

            </div>
        )
    }
}

