import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import IndexPage from './containers/IndexPage/IndexPage'
import Main from './containers/Main/Main'

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact>
          <IndexPage />
        </Route>
        <Main />
        <Redirect to={'/'} />
      </Switch>
    )
  }
}

export default App
