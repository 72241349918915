import React from 'react'
import classes from './BtnScroll.module.css'

export default function BtnScroll() {
    return (
        <div className={classes.el}>
            <p>scroll</p>
        </div>
    )
}
