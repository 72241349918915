import React from 'react'
import classes from './CustomBalloon.module.css'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'

function CustomBalloon(props) {
  const cls = [classes.CustomBaloon]
  if (props.Baloon) {
    cls.push(classes.open)
  }

  return (
    <>
      {props.index === props.id ? (
        <div className={cls.join(' ')}>
          <div className={classes.wrapper}>
            <figure className={classes.Figure}>
              <img
                src={props.points[props.index].factoryPhoto}
                alt="factory"></img>
            </figure>

            <h2 className={classes.title}>{props.points[props.index].title}</h2>

            <ul className={classes.list}>
              <li>Адрес: {props.points[props.index].adress}</li>
              <li>Отрасль: {props.points[props.index].industry}</li>
              <li>Даты: {props.points[props.index].date}</li>
              <li>Тип конфликта: {props.points[props.index].typeConflict}</li>
            </ul>

            <p className={classes.text}>{props.points[props.index].content}</p>

            {/* <NavLink
              to={{
                pathname: `/${props.points[props.index].title[0]
                  .toLowerCase()
                  .replaceAll(' ', '')}`,
              }}
              className={classes.link}>
              Подробнее
            </NavLink> */}

            <button
              className={classes.link}
              onClick={() =>
                props.history.push(
                  '/map/' +
                    props.points[props.index].title[0]
                      .toLowerCase()
                      .replaceAll(' ', '')
                )
              }>
              Подробнее
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default withRouter(CustomBalloon)
