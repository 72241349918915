
import React, { Component } from 'react'
import classes from './Header.module.css'


export default class Header extends Component {
    render() {
        return (
            <header>
                <h1 className={classes.headerItem}>PW</h1>
                <h1 className={classes.headerItem} hidden={this.props.isHidden}>2021 © Petro Workers</h1>
            </header>
        )
    }
}
