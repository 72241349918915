import React from 'react'
import classes from './Four.module.css'

export default function Four() {
    return (
        <div className={classes.Four}>
            
        </div>
    )
}
