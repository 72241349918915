import React, { Component } from 'react'
import Header from '../../components/Header/Header'
import BtnFullscreen from '../../components/BtnFullscreen/BtnFullscreen'
import BtnScroll from '../../components/BtnScroll/BtnScroll'
import One from '../../components/Slides/One/One'
import Two from '../../components/Slides/Two/Two'
import Three from '../../components/Slides/Three/Three'
import Four from '../../components/Slides/Four/Four'
import Footer from '../../components/Slides/Footer/Footer'

import classes from './IndexPage.module.css'

export default class IndexPage extends Component {
  render() {
    return (
      <>
        <Header isHidden={false} />
        <BtnFullscreen />
        <BtnScroll />

        <div className={classes.outerWrapper}>
          <div className={classes.wrapper}>
            <One />
            <Two />
            <Three />
            <Four />
            <Footer />
          </div>
        </div>
      </>
    )
  }
}
