import React, { Component } from 'react'
import 'swiper/swiper-bundle.css'
import './Slider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

export default class Slider extends Component {
  render() {
    SwiperCore.use([Navigation])
    let cls = ['swiper-slide', 'map_slide']

    return (
      <>
        <div className="map__slider">
          <div className="map__slider-wrapper">
            <Swiper
              slidesPerView={4}
              navigation
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
                1440: {
                  slidesPerView: 4,
                },
              }}>
              <div className="swiper-wrapper slider__items">
                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark}>
                  <div className="map__year">1917</div>
                  <button className="btn">Ноябрь - Декабрь</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark2}>
                  <div className="map__year">1918</div>

                  <button className="btn">Январь - Август</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark3}>
                  <div className="map__year">1918</div>

                  <button className="btn">Сентябрь - Декабрь</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark4}>
                  <div className="map__year">1919</div>

                  <button className="btn ">Январь - Май</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark5}>
                  <div className="map__year">1919</div>

                  <button className="btn ">Июнь - Август</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark6}>
                  <div className="map__year">1919</div>

                  <button className="btn ">Сентябрь - Декабрь</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark7}>
                  <div className="map__year">1920</div>

                  <button className="btn">Январь - Декабрь</button>
                </SwiperSlide>

                <SwiperSlide
                  className={cls.join(' ')}
                  onClick={this.props.showMark8}>
                  <div className="map__year">1921</div>

                  <button className="btn">Январь - Март</button>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </>
    )
  }
}
