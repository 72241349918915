import React from 'react'
import classes from './BtnFullscreen.module.css'
import fullscreen from './fullscreen.svg'
 
function BtnFullscreen() {
    

    function goFullScreen() {

        const elem = document.documentElement;
    
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    function exitFullScreen() {

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    
    }

    
    function isFullscreen() {
        if (document.fullscreenElement === null) {
            goFullScreen();
        } else {
            exitFullScreen();
        }
    }

    return ( 

            <button onClick={isFullscreen} className={classes.el}>
                <img src={fullscreen} alt={"fullscreen"}/>
            </button>
            
    )
       
}


export default BtnFullscreen