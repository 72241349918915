import React from 'react'
import ArrowToMap from './arrow__to-map.svg'
import { NavLink } from 'react-router-dom'
import classes from './ButtonToMap.module.css'

export default function ButtonToMap(props) {
  return (
    <NavLink to="/map" className={classes.wrapper}>
      <img src={ArrowToMap} alt="to map" />
      <h2 className={classes.title}>Назад к карте</h2>
    </NavLink>
  )
}
