import React, { Component } from 'react'
import classes from './NavCircleImg.module.css'

export default class NavCircleImg extends Component {


    render() {
        return (
            <figure className = {classes.item}>

                <img 
                
                    src={this.props.name} 
                    alt={"circle"} 
                />
            </figure>
        )
    }
}
