import React from 'react'
import classes from './Three.module.css'

export default function Three() {
    return (
        <div className={classes.Three}>
            
        </div>
    )
}
