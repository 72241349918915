import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './Button.module.css'


export default function Button() {
    return (
        
        <NavLink to='/map' exact className={classes.btn}>Попробовать</NavLink>

    )
}
