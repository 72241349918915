import React, { Component } from 'react'
import classes from './Link.module.css'

export default class Link extends Component {
    render() {
        return (

        <div className={classes.item}>

            <a
                href={this.props.href}
                rel="nofollow noopener"
            >
                <img
                    className={classes.link} 
                    src={this.props.name} 
                    alt={"icon"} 
                />

            </a>
            
            <div className={classes.divider}></div>


        </div>
        )
    }
}

