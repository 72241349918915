import React from 'react'
import classes from './Two.module.css'
import background from './img/background.png'
import WrapperBar from '../../WrapperBar/WrapperBar'
import notebook from './img/notebook.png'
import Button from '../../UI/Button/Button'

export default function Two() {

      
    const wheel = () => {
    }

    return (
        <div onWheel={wheel} className={classes.slide}>

            <div className={classes.background}>
                <img src={background} alt="background" />
            </div>

            <div className={classes.wrapper}>
                <WrapperBar/>

                <figure className={classes.notebook}>
                    <img src={notebook} alt="notebook" />
                </figure>

                <div className={classes.arcticle}>
                    <h1>Временная карта</h1>
                    <p>
                        Карта убедительно показывает, что наиболее частые и сильные волнения происходили на крупнейших предприятиях города (Путиловский, Обуховский и др.), способных «заразить» более мелкие предприятия.
                    </p>

                    <Button/>
                </div>
            </div>

        </div>
    )
}
