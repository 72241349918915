import React, { Component } from 'react'
import classes from './Link.module.css'

export default class Link extends Component {
  render() {
    return (
      <a href={this.props.href} className={classes.el}>
        {this.props.href}
      </a>
    )
  }
}
