import React, { Component } from 'react'
import BtnFullscreen from '../../components/BtnFullscreen/BtnFullscreen'
import Header from '../../components/Header/Header'
import Drawer from '../../components/Navigation/Drawer/Drawer'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import classes from './Map.module.css'
import MenuToggle from '../../components/Navigation/MenuToggle/MenuToggle'
import Slider from '../../components/Slider/Slider'
import CustomBalloon from '../../components/CustomBalloon/CustomBalloon'

class MyMap extends Component {
  state = {
    Baloon: false,
    id: 0,
  }

  componentDidMount() {
    const slides = document.querySelectorAll('.map_slide')

    for (let i = 0; i < slides.length; i++) {
      slides[i].addEventListener('click', function () {
        let current = document.getElementsByClassName('active')

        if (current.length > 0) {
          current[0].className = current[0].className.replace(' active', '')
        }
        this.className += ' active'
      })
    }
  }

  toggleBaloonHandler = (id) => {
    console.log('its worked', id)
    this.setState({
      Baloon: true,
      id,
    })
  }

  render() {
    return (
      <>
        <Slider
          showMark={this.props.showMark}
          showMark2={this.props.showMark2}
          showMark3={this.props.showMark3}
          showMark4={this.props.showMark4}
          showMark5={this.props.showMark5}
          showMark6={this.props.showMark6}
          showMark7={this.props.showMark7}
          showMark8={this.props.showMark8}
        />
        <Header isHidden={true} />
        <MenuToggle
          onToggle={this.props.toggleMenuHandler}
          isOpen={this.props.menu}
        />
        <Drawer
          isOpen={this.props.menu}
          onClose={this.props.menuCloseHandler}
        />
        <BtnFullscreen />
        <YMaps>
          <div className={classes.wrapper}>
            <Map
              id="e860e2dd-e7d4-4cd4-907c-5caa4319d435"
              className={classes.map}
              defaultState={{ center: [59.936584, 30.348076], zoom: 12 }}
              options={{ suppressMapOpenBlock: true }}
              modules={['templateLayoutFactory', 'layout.ImageWithContent']}>
              {/* <Clusterer
                                options={{
                                    preset: 'islands#invertedVioletClusterIcons',
                                    groupByCoordinates: false,
                                }}
                                >
                                
                            </Clusterer> */}

              {this.props.points.map((coordinates, index) => (
                <React.Fragment key={index}>
                  <Placemark
                    onClick={() => this.toggleBaloonHandler(index)}
                    geometry={coordinates.coordinates}
                    options={{
                      hideIconOnBalloonOpen: false, //запрет на скрытие метки по клику на балун
                      balloonOffset: [10, -37],
                      iconLayout: 'default#image',
                      iconImageHref: `${this.props.points[index].icon}`,
                      iconImageSize: [45, 45],
                    }}
                    modules={[
                      'geoObject.addon.balloon',
                      'geoObject.addon.hint',
                    ]}
                  />
                  <CustomBalloon
                    {...this.props}
                    index={index}
                    {...this.state}
                  />
                </React.Fragment>
              ))}
            </Map>
          </div>
        </YMaps>
      </>
    )
  }
}

export default MyMap
