import React, { Component } from 'react'
import classes from './NavCircle.module.css'
import NavItem from './NavItem/NavItem'
import circleOne from './NavCircleImg/img/circle-one.svg'
import circleTwo from './NavCircleImg/img/circle-two.svg'
import circleThree from './NavCircleImg/img/circle-three.svg'
import circleFour from './NavCircleImg/img/circle-four.svg'
import petroWorkers from './NavCircleImg/img/logo_PW.svg'
import NavCircleImg from './NavCircleImg/NavCircleImg'

export default class NavCircle extends Component {
  state = {
    links: [
      { name: 'Библиотека', to: '/1' },
      { name: 'Карта', to: '/map' },
      { name: 'Инфографика', to: '/2' },
      { name: 'Видео', to: '/3' },
      { name: 'Галерея', to: '/4' },
      { name: 'Публикации', to: '/5' },
      { name: 'О нас', to: '/61' },
      { name: 'Хроника', to: '/7' },
    ],
    circleImgs: [
      { name: circleOne },
      { name: circleTwo },
      { name: circleThree },
      { name: circleFour },
      { name: petroWorkers },
    ],
  }

  render() {
    return (
      <div className={classes.menu}>
        {this.state.links.map((link, index) => {
          return <NavItem key={index} name={link.name} to={link.to} />
        })}

        {this.state.circleImgs.map((circle, index) => {
          return <NavCircleImg key={index} name={circle.name} />
        })}
      </div>
    )
  }
}
