import React from 'react'
import classes from './MenuToggle.module.css'

const MenuToggle = props => {
    const cls = [
        classes.MenuToggle
    ]

    if (props.isOpen) {
        cls.push(classes.open)
        return (
            <h1
            className={cls.join(' ')}
            onClick={props.onToggle}
        >
            Закрыть</h1>
       
        )
    }

   else {
    return (
        
        <h1
            className={cls.join(' ')}
            onClick={props.onToggle}
        >
            Меню</h1>
    )
   }
    
}

export default MenuToggle