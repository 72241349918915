import React, { Component } from 'react'
import classes from './SocialLinks.module.css'
import Link from './Link/Link'
import inst from './img/inst.svg'
import facebook from './img/facebook.svg'
import vk from './img/vk.svg'

export default class SocialLinks extends Component {

    state = {
        links: [
            {name: facebook, href: 'https://www.facebook.com/'},
            {name: vk, href: 'https://vk.com/'},
            {name: inst, href: 'https://www.instagram.com/'},

        ]
    }   
    render() {
        return (
            <>
            <div className={classes.links}>

                
                {this.state.links.map((link, index) => {
                    return (
                        <Link
                            key={index}
                            name={link.name}
                            href={link.href}
                        />

                    )
                })}
                 <h1 className={classes.text}> social</h1>
            
            </div>
            </>
        )
    }
}
